import { FeatureFlagKey } from "@epicbrief/shared/types"
import { CrmOnboardingStep } from "@epicbrief/shared/types/UserData"
import { FlowArrow, House, Users } from "@phosphor-icons/react"
import React, { useContext } from "react"
import { useLocation } from "react-router-dom"
import AuthContext from "../AuthContext"
import DealIcon from "../Images/DealIcon"
import SettingsIcon from "../Images/SettingsIcon"
import { SupportIcon } from "../Images/SupportIcon"
import TemplatesIcon from "../Images/TemplatesIcon"
import { ROUTES } from "./constants"
import { useFeatureFlag } from "./featureFlags"

const getSettingsNotificationLabel = (
  crmOnboardingStep?: CrmOnboardingStep,
) => {
  if (crmOnboardingStep === CrmOnboardingStep.SYNC) return "warning" as const
  return undefined
}

// Generates the menu items for the top and bottom menus, and the titles for all the routes
const useMenuItems = () => {
  const location = useLocation()
  const { currentUserData } = useContext(AuthContext)

  const dealPageActive = useFeatureFlag(FeatureFlagKey.DealSummary)
  const isHomePageActive = useFeatureFlag(FeatureFlagKey.HomePage)
  const isWorkflowsActive = useFeatureFlag(FeatureFlagKey.Workflows)

  const topMenuItems = [
    ...(isHomePageActive
      ? [
          {
            label: "Home",
            linkTo: ROUTES.HOME,
            icon: <House />,
            isActive: location.pathname.startsWith(ROUTES.HOME),
          },
        ]
      : []),
    {
      label: "Meetings",
      linkTo: ROUTES.MEETINGS,
      icon: <Users />,
      isActive: location.pathname.startsWith(ROUTES.MEETINGS),
    },
    {
      label: "Templates",
      linkTo: ROUTES.TEMPLATES,
      icon: <TemplatesIcon />,
      isActive: location.pathname.startsWith(ROUTES.TEMPLATES),
    },
    ...(dealPageActive
      ? [
          {
            label: "(PoC) Deal",
            linkTo: ROUTES.DEAL.SUMMARY,
            icon: <DealIcon />,
            isActive: location.pathname.startsWith(ROUTES.DEAL.MAIN),
          },
        ]
      : []),
    ...(isWorkflowsActive
      ? [
          {
            label: "Workflows",
            linkTo: ROUTES.WORKFLOWS,
            icon: <FlowArrow />,
            isActive: location.pathname.startsWith(ROUTES.WORKFLOWS),
          },
        ]
      : []),
  ]

  const bottomMenuItems = [
    {
      label: "Support",
      linkTo: ROUTES.SUPPORT,
      icon: <SupportIcon />,
      isActive: location.pathname.startsWith(ROUTES.SUPPORT),
    },
    {
      label: "Settings",
      linkTo: ROUTES.SETTINGS.INTEGRATIONS,
      icon: <SettingsIcon />,
      isActive: location.pathname.startsWith(ROUTES.SETTINGS.INTEGRATIONS),
      notificationLabel: getSettingsNotificationLabel(
        currentUserData?.crmOnboarding,
      ),
    },
  ]

  const routeLabels = {
    [ROUTES.MEETINGS]: "My Meetings",
    [ROUTES.TEMPLATES]: "Templates",
    [ROUTES.SUPPORT]: "Support",
  }

  return { topMenuItems, bottomMenuItems, routeLabels }
}

export default useMenuItems
