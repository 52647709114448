import * as anatomy from "@chakra-ui/anatomy"
import {
  createMultiStyleConfigHelpers,
  cssVar,
  defineStyleConfig,
  extendTheme,
} from "@chakra-ui/react"
import { Button } from "./button"
import { Menu } from "./menu"
import { colors } from "./colors"
import { Modal } from "./modal"
import { Drawer } from "./drawer"

// SHARED CONSTANTS
// For sharing style objects between different components such as Input and Textarea etc
const BASE_INPUT_FIELD_STYLES = {
  border: "solid 1px",
  borderColor: "gray.200",
  _placeholder: { color: "gray.400" },
}

// TODO: Inputs are bit of a mess. Ask design to do a UI kit for us.
const SMALL_INPUT_FIELD_STYLES = {
  fontSize: "sm",
  height: "2rem",
  marginBottom: 0,
  borderRadius: "0.5rem",
  padding: "0.5rem 0.75rem",
  lineHeight: "1.0",
  _placeholder: { fontSize: "sm" },
}

const BLOCK_HEADER = {
  minHeight: "1.5rem",
  fontWeight: "600",
  mb: "0.125rem",
  pl: 0,
}

const BLOCK_ITEM = {
  pl: "1rem",
  pb: "0.125rem",
  pt: "0.125rem",
  fontSize: "sm",
}

const MODAL_HEADER = {
  height: "3rem",
  fontSize: "1.5rem",
  padding: "0.5rem",
  fontWeight: "500",
  pl: 0,
}

export const FONTS = `'Inter', sans-serif`

const fonts = {
  heading: FONTS,
  body: FONTS,
}

// defined as const to be used for Select, Input, FormLabel etc
const LABEL_BACKGROUND_STYLES = {
  cursor: "pointer",
  backgroundColor: "gray.50",
  borderColor: "gray.50",
  borderRadius: "0.5rem",
  height: "2rem",
  maxWidth: "16rem",
  _hover: { cursor: "pointer" },
}

const { defineMultiStyleConfig: defineCardMultiStyleConfig } =
  createMultiStyleConfigHelpers(anatomy.cardAnatomy.keys)

const Card = defineCardMultiStyleConfig({
  baseStyle: {
    header: {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1rem",
      pb: "0",
    },
    body: {
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      pb: 0,
    },
    footer: { p: "0.25rem" },
    container: {
      width: "18.875rem",
      // This is "gray.200" but chakra var doesn't work here
      border: "1px solid rgba(30, 31, 42, 0.1)",
      boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0.05)",
    },
  },
  variants: {
    // TODO: Not touching onboarding dark theme yet
    onBoarding: {
      container: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "carteBlanche.1000",
        bg: "coarseWool.700",
        width: "11rem",
        height: "10rem",
        fontWeight: "normal",
        borderRadius: "12px",
        gap: "1rem",
        _hover: {
          cursor: "pointer",
          backgroundColor: "gray.50",
        },
        _active: {
          cursor: "pointer",
          backgroundColor: "white",
          transform: "scale(0.95)",
        },
      },
    },
  },
})

const Heading = defineStyleConfig({
  baseStyle: {
    color: "gray.800",
  },
  variants: {
    // TODO: Not touching onboarding for now
    onboarding: {
      color: "gray.500",
      fontSize: "1.5rem",
      fontWeight: 500,
    },
    modalTableColumn: {
      color: "gray.500",
      fontSize: "sm",
      fontWeight: "normal",
      fontFamily: "Inter",
      lineHeight: "14px",
      padding: "8px 4px",
    },
    withBreadcrumbs: {
      mt: "0.5rem",
      mb: "1rem",
      fontSize: "1.5rem",
      color: "gray.1000",
      fontWeight: "semi-bold",
    },
    summaryBlockHeader: {
      ...BLOCK_HEADER,
      fontSize: "sm",
    },
    modalHeader: { ...MODAL_HEADER },
  },
})

const {
  definePartsStyle: defineTagPartsStyle,
  defineMultiStyleConfig: defineTagMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.tagAnatomy.keys)
const Tag = defineTagMultiStyleConfig({
  baseStyle: defineTagPartsStyle({
    container: {
      p: "0.25rem",
      m: "0",
      fontWeight: 400,
    },
  }),
  variants: {
    roleTag: defineTagPartsStyle({
      container: {
        bg: "gray.50",
        height: "fit-content",
        fontSize: "10px",
      },
    }),
  },
})

const Container = defineStyleConfig({
  baseStyle: {
    display: "flex",
    padding: 0,
  },
  variants: {
    outlined: {
      // TODO: Dark theme
      border: "solid 1px",
      borderColor: "gray.200",
      padding: "4rem",
      borderRadius: "8px",
    },
    labelBackground: {
      alignItems: "center",
      backgroundColor: "gray.100",
      width: "fit-content",
      margin: 0,
      borderRadius: "6px",
    },
    modalLabel: {
      alignItems: "center",
      backgroundColor: "gray.100",
      width: "fit-content",
      margin: 0,
      borderRadius: "8px",
      padding: "0.25rem 0.5rem",
      fontSize: "sm",
      cursor: "pointer",
    },
  },
})

const Box = defineStyleConfig({
  variants: {
    label: {
      textAlign: "left",
      backgroundColor: "gray.100",
      width: "fit-content",
    },
  },
})

const Text = defineStyleConfig({
  baseStyle: {
    padding: "2",
  },
  variants: {
    tableHeader: {
      margin: 0,
      textTransform: "none",
      fontSize: "sm",
      padding: 0,
      color: "gray.800",
      fontWeight: "500",
    },
    tableText: {
      textAlign: "start",
      fontSize: "sm",
      margin: 0,
      padding: "0 0.5rem",
    },
    inCell: {
      margin: 0,
      padding: 0,
      textAlign: "start",
      textOverflow: "fade",
    },
    textWithLinks: {
      textAlign: "center",
      fontSize: "xs",
      mt: "4",
    },
    blockEditorItem: {
      ...BLOCK_ITEM,
      fontSize: "14px",
      _focusVisible: {
        outline: "none",
      },
    },
    blockEditorHeader: {
      fontSize: "14px",
      ...BLOCK_HEADER,
      pt: 0,
      paddingLeft: 0,
      fontWeight: 500,
      _focusVisible: {
        outline: "none",
      },
    },
  },
})

const Divider = defineStyleConfig({
  baseStyle: { borderColor: "gray.100" },
})

const Link = defineStyleConfig({
  baseStyle: {
    _hover: {
      textDecoration: "none",
    },
  },
})

const FormLabel = defineStyleConfig({
  baseStyle: {
    fontSize: "sm",
    mt: "0.375rem",
  },
  variants: {
    labelBackground: {
      ...LABEL_BACKGROUND_STYLES,
      cursor: "pointer",
      padding: "0.25rem 1rem",
      textAlign: "center",
      height: "2rem",
      width: "fit-content",
    },
    button: {
      display: "flex",
      flexDirection: "row",
      gap: "8px",
    },
    error: {
      as: "label",
      color: "red",
      mt: "0.25rem",
      mb: "0",
    },
  },
})

const Tooltip = defineStyleConfig({
  baseStyle: {
    borderRadius: "12px",
    padding: "4px 8px",
  },
})

const {
  definePartsStyle: defineListPartsStyle,
  defineMultiStyleConfig: defineListMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.listAnatomy.keys)

const List = defineListMultiStyleConfig({
  baseStyle: defineListPartsStyle({
    container: { padding: 0, mb: 0 },
    item: { margin: 0, mb: "0.75rem", fontSize: "sm" },
    icon: { size: "xs" },
  }),
  variants: {
    blockItems: defineListPartsStyle({
      container: { gap: "0.5rem" },
      item: {
        ...BLOCK_ITEM,
        mb: 0,
        pb: "0.125rem",
        pt: "0.125rem",
        pl: "0.125rem",
      },
    }),
  },
})

const {
  definePartsStyle: defineFormPartsStyle,
  defineMultiStyleConfig: defineFormMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.formAnatomy.keys)

const FormControl = defineFormMultiStyleConfig({
  baseStyle: defineFormPartsStyle({
    container: { display: "flex", alignItems: "center" },
  }),
})

const {
  definePartsStyle: defineInputPartsStyle,
  defineMultiStyleConfig: defineInputMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.inputAnatomy.keys)

// @ref https://chakra-ui.com/docs/components/input/theming
const Input = {
  defaultProps: { size: "sm" },
  ...defineInputMultiStyleConfig({
    baseStyle: defineInputPartsStyle({
      field: BASE_INPUT_FIELD_STYLES,
    }),
    sizes: {
      sm: defineInputPartsStyle({
        field: { ...SMALL_INPUT_FIELD_STYLES },
      }),
      md: defineInputPartsStyle({
        field: { padding: "8px 12px", fontSize: "sm" },
      }),
    },
    variants: {
      search: {
        field: {
          width: "212px",
          paddingLeft: "2rem",
          _placeholder: { color: "gray.400", fontSize: "sm" },
        },
      },
      editableHeader: {
        field: {
          ...MODAL_HEADER,
          border: "none",
          borderRadius: "0.5rem",
          ml: "-0.5rem",
          _invalid: { border: "solid 2px", borderColor: "error" },
          _placeholder: { fontSize: "1.5rem" },
        },
      },
      editableSubheader: {
        field: {
          ...MODAL_HEADER,
          border: "none",
          borderRadius: "0.5rem",
          ml: "-0.5rem",
          fontSize: "1rem",
          _invalid: { border: "solid 2px", borderColor: "error" },
          _placeholder: { fontSize: "1rem" },
        },
      },
      labelBackground: { field: { ...LABEL_BACKGROUND_STYLES } },
    },
  }),
}

const Textarea = defineStyleConfig({
  defaultProps: { size: "sm" },
  baseStyle: {
    ...BASE_INPUT_FIELD_STYLES,
    fontSize: "14px",
    width: "100%",
    border: 0,
    _focus: {
      boxShadow: "none",
    },
    background: "inherit",
  },
})

const {
  definePartsStyle: defineSelectPartsStyle,
  defineMultiStyleConfig: defineSelectMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.selectAnatomy.keys)

const Select = {
  defaultProps: { size: "sm" },
  ...defineSelectMultiStyleConfig({
    baseStyle: defineSelectPartsStyle({
      field: {
        cursor: "pointer",
        ...BASE_INPUT_FIELD_STYLES,
      },
    }),
    variants: {
      labelBackground: {
        field: {
          ...LABEL_BACKGROUND_STYLES,
        },
      },
    },
    sizes: {
      sm: {
        field: {
          ...SMALL_INPUT_FIELD_STYLES,
          pr: "2.25rem",
          maxWidth: "16rem",
        },
      },
    },
  }),
}

const {
  definePartsStyle: defineCheckBoxPartsStyle,
  defineMultiStyleConfig: defineCheckBoxMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.checkboxAnatomy.keys)

const Checkbox = defineCheckBoxMultiStyleConfig({
  baseStyle: defineCheckBoxPartsStyle({
    container: {
      fontSize: "xs",
    },
    control: {
      borderRadius: "0.25rem",
      _checked: {
        backgroundColor: "blue.500",
        borderColor: "blue.500",
        _hover: {
          backgroundColor: "blue.300",
          borderColor: "blue.300",
        },
      },
    },
    label: {
      userSelect: "inherit",
      _checked: { textDecoration: "line-through" },
    },
  }),
  variants: {
    labelSelect: {
      container: {
        flex: "1 1 auto",
        minWidth: "calc(100% / 4)",
        height: "2rem",
        padding: "1rem",
        border: "solid 1px",
        borderColor: "gray.100",
        _focusWithin: {
          borderColor: "blue.500",
        },
        backgroundColor: "gray.100",
        borderRadius: "0.5rem",
        _checked: {
          borderColor: "blue.500",
          _focusWithin: {
            borderColor: "blue.500",
            fontWeight: "bold",
          },
        },
      },
      control: {
        border: 0,
        maxWidth: 0,
        opacity: 0, // hide but keep accessibility, show label only
      },
      label: {
        fontSize: "sm",
        margin: "0 auto",
        _checked: {
          textDecoration: "none",
          borderColor: "blue.500",
          color: "blue.500",
        },
      },
    },
  },
})

const {
  definePartsStyle: defineTabsPartsStyle,
  defineMultiStyleConfig: defineTabsMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.tabsAnatomy.keys)

const Tabs = defineTabsMultiStyleConfig({
  defaultProps: {
    size: "sm",
  },
  sizes: {
    sm: {
      tab: { padding: "1rem 0" },
    },
  },
  baseStyle: defineTabsPartsStyle({
    tablist: {
      paddingLeft: "16px",
    },
    tab: {
      padding: "1rem 0",
      fontSize: "14px",
      height: "3rem",
      _selected: {
        color: "inherit",
        borderColor: "black",
        fontWeight: "500",
      },
    },
  }),
})

const {
  definePartsStyle: defineBreadcrumbPartsStyle,
  defineMultiStyleConfig: defineBreadcrumbMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.breadcrumbAnatomy.keys)

const Breadcrumb = defineBreadcrumbMultiStyleConfig({
  baseStyle: defineBreadcrumbPartsStyle({
    container: {
      ml: "-2rem", // Theming doesn't support touching the ol styles hence this ugly offset
    },
    link: {
      color: "gray.800",
    },
    separator: {},
    item: {
      fontSize: "sm",
    },
  }),
})

const {
  definePartsStyle: defineTablePartsStyle,
  defineMultiStyleConfig: defineTableMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.tableAnatomy.keys)

const Table = defineTableMultiStyleConfig({
  baseStyle: defineTablePartsStyle({
    table: {},
    thead: {},
    tbody: {},
    tr: {
      th: {
        textTransform: "none",
        fontSize: "sm",
        fontWeight: "500",
        color: "gray.800",
      },
    },
    td: {},
  }),
})

const {
  definePartsStyle: definePopoverPartsStyle,
  defineMultiStyleConfig: definePopoverMultiStyleConfig,
} = createMultiStyleConfigHelpers(anatomy.popoverAnatomy.keys)

const $arrowBg = cssVar("popper-arrow-bg")
const Popover = definePopoverMultiStyleConfig({
  baseStyle: definePopoverPartsStyle({
    popper: {
      background: "violet.600",
      borderRadius: "0.5rem",
      boxShadow:
        "0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)",
    },
    arrow: {
      // @ref https://github.com/chakra-ui/chakra-ui/issues/4695
      [$arrowBg.variable]: "colors.violet.600",
      pt: "1rem",
    },
    content: {
      background: "violet.600",
      color: "white",
      border: "none",
      pb: "0.75rem",
    },
    header: {
      pt: "1rem",
      border: "none",
      background: "violet.600",
      color: "white",
      fontWeight: 500,
      borderRadius: "0.5rem",
    },
  }),
  variants: {
    changeLog: definePopoverPartsStyle({
      popper: {
        backgroundColor: "white",
        maxWidth: "400px",
        border: "solid 1px",
        borderColor: "gray.200",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      },
      arrow: {
        // @ref https://github.com/chakra-ui/chakra-ui/issues/4695
        [$arrowBg.variable]: "white",
        pt: "none",
      },
      body: {
        fontSize: "12px",
      },
      content: {
        width: "auto",
        minWidth: "250px",
        backgroundColor: "white",
        color: "gray.800",
        p: 0,
      },
    }),
    expandingCell: definePopoverPartsStyle({
      popper: {
        background: "gray.50",
        borderRadius: "0",
        boxShadow: "0 0 0 1px #E5E7EB, 0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
        border: 0,
      },
      content: {
        borderRadius: "0",
        background: "gray.50",
        color: "inherit",
        border: "0",
      },
    }),
    askAI: definePopoverPartsStyle({
      popper: {
        backgroundColor: "white",
        maxWidth: "400px",
        border: "solid 1px",
        borderColor: "gray.200",
        borderRadius: "8px",
        boxShadow: "0px 1px 2px 0px rgba(0, 0, 0, 0.05)",
      },
      content: {
        backgroundColor: "white",
        color: "gray.800",
        p: 0,
      },
    }),
    list: definePopoverPartsStyle({
      popper: {
        backgroundColor: "white",
      },
      content: {
        maxWidth: "241px",
        backgroundColor: "white",
        border: "1px solid",
        borderColor: "gray.200",
        padding: 0,
        boxShadow: "0px 1px g2px 0px rgba(0, 0, 0, 0.05)",
      },
      body: {
        padding: 0,
      },
    }),
  },
})

const theme = extendTheme({
  styles: {
    global: {
      body: {
        bg: "white",
        color: "gray.800",
      },
      /**
       * NOTE 5.4.2023:
       * The Chakra UI theming for Td and Th is straight broken and doesn't apply many styles.
       * The documentation for theming tables just says TODO.
       *
       * Applying global styles straight to the html elements like below works for now
       * but will force us to apply more styles at the component level as we start needing
       * more versions of these elements.
       *
       * We should keep these styles minimal and use variants of Text etc to apply more styles.
       */
      td: {
        verticalAlign: "top",
        fontSize: "sm",
        borderBottomWidth: "1px",
        wordWrap: "break-word",
      },
    },
  },
  components: {
    Heading,
    Button,
    Text,
    Container,
    Divider,
    Drawer,
    Card,
    Link,
    Checkbox,
    Input,
    Textarea,
    Box,
    Modal,
    FormLabel,
    FormControl,
    Select,
    Breadcrumb,
    Tag,
    List,
    Tabs,
    Popover,
    Menu,
    Tooltip,
    Table,
  },
  colors,
  fonts,
})

export default theme
