import { modalAnatomy } from "@chakra-ui/anatomy"
import { createMultiStyleConfigHelpers } from "@chakra-ui/styled-system"

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(modalAnatomy.keys)

export const Modal = defineMultiStyleConfig({
  baseStyle: definePartsStyle({
    header: {
      fontSize: "lg",
      fontWeight: "medium",
      borderBottom: "solid 1px",
      borderColor: "gray.100",
      padding: "12px 16px",
    },
    body: {
      padding: "16px",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      borderBottom: "solid 1px",
      borderColor: "gray.100",
    },
    closeButton: {
      color: "gray.500",
      backgroundColor: "gray.100",
      borderRadius: "50%",
      height: "24px",
      width: "24px",
      top: "13px",
      right: "16px",
      fontSize: "10px",
      _hover: {
        backgroundColor: "gray.200",
      },
    },
    footer: {
      padding: "12px 16px",
    },
  }),
  sizes: {
    "6xl": {
      dialog: { maxWidth: "76rem" },
    },
    full: {
      dialogContainer: {
        padding: "32px",
      },
      dialog: {
        borderRadius: "8px",
        minHeight: "unset",
        height: "calc(100vh - 64px)",
      },
    },
  },
  variants: {
    templateEditor: {
      dialog: {},
      dialogContainer: {},
      header: { fontWeight: "500", borderBottom: "solid 1px" },
      body: {},
      closeButton: {
        color: "gray.500",
        backgroundColor: "gray.200",
        borderRadius: "50%",
        height: "1.5rem",
        width: "1.5rem",
      },
      overlay: {},
    },
  },
})
